<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        component-top
      div(class="content-right")
        pr-banner

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>


export default {
  name: "TopIndex",
  components: {
  },
  data() {
    return {
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
  }
};
</script>
